<template>
  <div>
    <span
      class="link"
      @click="back"
    > Назад </span>
    <!-- Client details -->
    <b-overlay
      class="mt-2"
      :show="loading"
    >
      <div class="card p-2">
        <h3>Mijoz ma'lumotlari</h3>
        <div
          v-if="client"
          class="d-flex"
        >
          <div class="table-responsive">
            <table class="table table-bordered table-hover">
              <tr>
                <th>Пасспорт</th>
                <td>{{ client.passport }}</td>
                <th>Автосписание:</th>
                <td>
                  <b-overlay :show="loading_checkbox">
                    <b-form-checkbox
                      v-model="client.auto"
                      :value="1"
                      :unchecked-value="0"
                      switch
                      inline
                      @input="toggleChange"
                    />
                  </b-overlay>
                </td>
              </tr>
              <tr>
                <th>Пинфл</th>
                <td>{{ client.pin }}</td>
                <th>Не оплачено:</th>
                <td>{{ client.remaining_amount | formatPrice }} so'm</td>
              </tr>
              <tr>
                <th>Лоан ИД</th>
                <td>{{ client.loan_id }}</td>
                <th>Общая задолженность:</th>
                <td>{{ client.debit_amount | formatPrice }} so'm</td>
              </tr>
              <tr>
                <th>Внешный ИД</th>
                <td>{{ client.ext_id }}</td>
                <th>Общая оплаченная сумма:</th>
                <td class="text-success font-weight-bolder">
                  {{ client.paid_amount | formatPrice }} so'm
                </td>
              </tr>
              <tr>
                <th>ФИО</th>
                <td class="text-primary font-weight-bolder">
                  <span
                    class="link"
                    @click="goToContract"
                  >{{
                    [client.lastname, client.firstname, client.middlename].join(
                      " "
                    )
                  }}</span>
                </td>
                <th>Телефон:</th>
                <td>{{ client.phones.join(", ") }}</td>
              </tr>
            </table>
          </div>
          <div class="d-flex flex-column ml-2">
            <!--  -->
            <b-button
              variant="outline-primary"
              class="mt-1"
              @click="payment('oldContracts')"
            >
              <feather-icon
                icon="Edit2Icon"
                size="16"
              />
              {{ $t("Редактировать") }}
            </b-button>
            <!--  -->
            <b-button
              variant="outline-primary"
              class="mt-1"
              @click="getItem"
            >
              {{ $t("Обновит") }}
            </b-button>

            <b-button
              variant="outline-danger"
              class="mt-1"
              @click="payment('oldContracts')"
            >
              <feather-icon
                icon="Trash2Icon"
                size="16"
              />
              {{ $t("Удалить клиента") }}
            </b-button>
          </div>
        </div>
      </div>
    </b-overlay>
    <!-- Payment Details -->
    <b-overlay :show="loading_payment">
      <div class="card p-2">
        <h3>To'lov ma'lumotlari</h3>

        <div class="table-responsive">
          <table class="table table-bordered table-hover">
            <thead>
              <tr>
                <th>#</th>
                <th>ПИНФЛ</th>
                <th>Номер карты</th>
                <th>Дата платежа</th>
                <th>Сумма</th>
                <th>Владелец карты</th>
                <th>Принадлежность пользователю</th>
                <th>Статус</th>
                <th>Действие</th>
              </tr>
            </thead>
            <tbody>
              <template v-if="paymentInfo && paymentInfo.data.length">
                <tr
                  v-for="(item, index) in paymentInfo.data"
                  :key="index"
                >
                  <td>{{ orderOfList(index) }}</td>
                  <td>{{ item.pin }}</td>
                  <td>{{ item.card_mask }}</td>
                  <td>{{ item.date }}</td>
                  <td
                    class="text-success font-weight-bolder"
                    :class="{'text-danger': item.status=='Cancelled'}"
                  >
                    {{ item.amount | formatPrice }}
                  </td>
                  <td>{{ item.card_owner }}</td>
                  <td>{{ item.type }}</td>
                  <td
                    :class="
                      item.status == 'OK' ? 'text-success' : 'text-danger'
                    "
                  >
                    {{ item.status }}
                  </td>
                  <td class="d-flex">
                    <b-button
                      variant="outline-info"
                      class="ml-2 btn-tour-skip text-uppercase"
                      @click="getInfoTooltip(item)"
                    >
                      <feather-icon
                        icon="EyeIcon"
                        size="16"
                      />
                    </b-button>

                    <b-button
                      v-if="item.status!='Cancelled'"
                      variant="outline-danger"
                      class="ml-1"
                      @click="rejectPayment(item)"
                    >
                      <feather-icon
                        icon="CornerUpLeftIcon"
                        size="16"
                      />
                      {{ $t("Cancel") }}
                    </b-button>
                  </td>
                </tr>
              </template>
              <template v-else>
                <tr>
                  <td
                    colspan="9"
                    class="text-center"
                  >
                    Ma'lumot topilmadi
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
          <div v-if="paymentInfo && paymentInfo.data.length">
            <b-pagination
              :value="1"
              :total-rows="paymentInfo.total"
              :per-page="filter.per_page"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="onPageChange"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </div>
    </b-overlay>
    <div v-if="payment_info_modal">
      <b-modal
        v-model="payment_info_modal"
        size="md"
        centered
        title="Доп/Инфо"
        hide-footer
        no-close-on-backdrop
        @close="payment_info_modal = false"
        @cancel="payment_info_modal = false"
      >
        <div>
          <table class="table table-bordered table-striped">
            <tbody>
              <tr>
                <td>Исполнитель</td>
                <td>System</td>
              </tr>
              <tr>
                <td>Отменено пользователем</td>
                <td>-</td>
              </tr>
              <tr>
                <td>Отмененное время</td>
                <td />
              </tr>
              <tr>
                <td>Время_создания</td>
                <td>{{ paymentInfoForModal.uni_access_created_at }}</td>
              </tr>
              <tr>
                <td>ID Транзакции</td>
                <td>{{ paymentInfoForModal.transaction_id }}</td>
              </tr>
              <tr>
                <td>EXT ID</td>
                <td>{{ paymentInfoForModal.ext_id }}</td>
              </tr>
              <tr>
                <td>RRN</td>
                <td>{{ paymentInfoForModal.transaction_id }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { showToast } from '@/utils/toast'

export default {
  name: 'Index',
  components: {},
  data() {
    return {
      loading: false,
      loading_payment: false,
      client: null,
      paymentInfo: null,
      payment_info_modal: false,
      paymentInfoForModal: null,
      loading_checkbox: false,
      page: 1,
      filter: {
        per_page: 50,
      },
    }
  },
  computed: {},
  watch: {
    page(newVal, oldVal) {
      if (newVal && newVal !== oldVal) this.getPaymentsAction()
    },
  },
  mounted() {
    this.getItem()
  },
  methods: {
    ...mapActions({
      getItemAction: 'resource/getAutopayClient',
      autopayToggle: 'resource/autopayToggle',
      getPayments: 'resource/getAutopayPayments',
      autopayPaymentReject: 'resource/autopayPaymentReject',
    }),
    goToContract() {
      if (this.client.contract_id) {
        this.goTo('payment-show', { id: this.client.contract_id })
      } else if (this.client.old_contract_id) {
        this.goTo('contract-old-show', { id: this.client.old_contract_id })
      }
    },
    showClient(id) {
      this.goTo('AutopayClient-show', { id })
    },
    toggleChange() {
      this.loading_checkbox = true
      this.autopayToggle({ loan_id: this.client.loan_id, auto: this.client.auto }).then(res => {
        showToast('success', this.$t('Успешно сохранено'), 'CheckCircleIcon')
      }).finally(() => {
        this.loading_checkbox = false
      })
    },
    getItem() {
      this.loading = true
      this.getItemAction({ id: this.$route.params.id })
        .then(res => {
          this.client = res.data
          this.loading = false
          this.getPaymentsAction()
        })
        .finally(() => {
          this.loading = false
        })
    },
    getPaymentsAction() {
      this.loading_payment = true
      this.getPayments({
        page: this.page,
        per_page: this.filter.per_page,
        loan_id: this.client.loan_id,
      })
        .then(res => {
          this.paymentInfo = res.data
          this.loading_payment = false
        })
        .finally(() => {
          this.loading_payment = false
        })
    },
    onPageChange(page) {
      this.page = page
    },
    orderOfList(index) {
      return (this.page - 1) * this.filter.per_page + index + 1
    },
    rejectPayment(info) {
      this.$bvModal
        .msgBoxConfirm("Ushbu to'lovni bekor qilmoqchimisiz?", {
          title: 'Tasdiqlang',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Ha',
          cancelTitle: 'Yo`q',
          cancelVariant: 'outline-secondary',
          centered: true,
        })
        .then(() => {
          this.autopayPaymentReject({ id: info.id }).then(res => {
            showToast('success', this.$t('Успешно сохранено'), 'CheckCircleIcon')
          })
        })
    },
    getInfoTooltip(info) {
      this.payment_info_modal = true
      this.paymentInfoForModal = info
    },
    today() {
      const today = new Date()
      const dd = String(today.getDate()).padStart(2, '0')
      const mm = String(today.getMonth() + 1).padStart(2, '0') // January is 0!
      const yyyy = today.getFullYear()
      return `${yyyy}-${mm}-${dd}`
    },
  },
}
</script>

<style scoped></style>
